const sv = {
  header: {
    languages: {
      swedish: 'Svenska',
      english: 'Engelska'
    },
    logo: 'Logo'
  },
  common: {
    title: 'Grace Chan | Frontend-utvecklare',
    name: 'Grace Chan'
  },
  home: {
    name: 'Hem',
    title: 'Frontend-utvecklare',
    inMalmo: 'i Malmö',
    arrow: 'Pil',
    contacts: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/yintinggrace/',
        icon: 'fa-brands fa-linkedin',
        openNewTab: true
      },
      {
        name: 'GitHub',
        link: 'https://github.com/yintinggrace/',
        icon: 'fa-brands fa-github',
        openNewTab: true
      },
      {
        name: 'E-post',
        link: 'mailto:yintingchancytg@gmail.com',
        icon: 'fa-solid fa-envelope',
        openNewTab: false
      }
    ],
    iSpecialiseIn: 'Jag är specialiserad på',
    specialization: [
      {
        name: 'React',
        uri: 'react-negative.svg'
      },
      {
        name: 'Next.js',
        uri: 'next-js-negative.svg'
      },
      {
        name: 'TypeScript',
        uri: 'typescript-negative.svg'
      }
    ]
  },
  aboutMe: {
    name: 'Om mig',
    facts: [
      {
        fact: 'Högt motiverad React-entusiast som skapar användargränssnitt för en förhöjd upplevelse.',
        uri: 'react.png',
        alt: 'React'
      },
      {
        fact: 'Jag älskar teamwork – det är nyckeln till att nå större höjder och göra resan mer angenäm!',
        uri: 'team.png',
        alt: 'Team'
      },
      {
        fact: 'Mer än bara kodning – jag integrerar UX-design för välfungerande och snygga webbplatser.',
        uri: 'ui-ux.png',
        alt: 'UI/UX'
      },
      {
        fact: 'Jag övergick från socialt arbete till tech — att göra en påverkan är hur spännande som helst!',
        uri: 'computer.png',
        alt: 'Dator'
      },
      {
        fact: 'Dagtid kodskapare, nattetid konstnärlig utforskare, semestertid nyfiken äventyrare.',
        uri: 'fun.png',
        alt: 'Hobbies'
      },
      {
        fact: 'Kaffe är trevligt, men jag håller mig till te hela dagen.',
        uri: 'tea.png',
        alt: 'Te'
      }
    ]
  },
  skills: {
    name: 'Färdigheter',
    filterBtn: 'Filtrera',
    selectAll: 'Markera alla',
    unselectAll: 'Avmarkera alla',
    categories: [
      {
        category: 'Webbutvecklingsspråk',
        content: [
          {
            name: 'C#',
            uri: 'c-sharp.svg'
          },
          {
            name: 'CSS',
            uri: 'css.svg'
          },
          {
            name: 'HTML',
            uri: 'html.svg'
          },
          {
            name: 'Java',
            uri: 'java.svg'
          },
          {
            name: 'JavaScript',
            uri: 'javascript.svg'
          },
          {
            name: 'PHP',
            uri: 'php.svg'
          },
          {
            name: 'TypeScript',
            uri: 'typescript.svg'
          }
        ]
      },
      {
        category: 'JavaScript-ramverk & bibliotek',
        content: [
          {
            name: 'D3',
            uri: 'd3.svg'
          },
          {
            name: 'Express.js',
            uri: 'express-js.svg'
          },
          {
            name: 'Font Awesome',
            uri: 'font-awesome.svg'
          },
          {
            name: 'i18next',
            uri: 'i18next.svg'
          },
          {
            name: 'Jest',
            uri: 'jest.svg'
          },
          {
            name: 'Next.js',
            uri: 'next-js.svg'
          },
          {
            name: 'Node.js',
            uri: 'node-js.svg'
          },
          {
            name: 'PropTypes'
          },
          {
            name: 'React',
            uri: 'react.svg'
          },
          {
            name: 'React Router',
            uri: 'react-router.svg'
          },
          {
            name: 'Redux',
            uri: 'redux.svg'
          }
        ]
      },
      {
        category: 'Styling',
        content: [
          {
            name: 'Responsiv webbdesign'
          },
          {
            name: 'Sass',
            uri: 'sass.svg'
          },
          {
            name: 'styled-components',
            uri: 'styled-components.svg'
          }
        ]
      },
      {
        category: 'Data',
        content: [
          {
            name: 'JSON',
            uri: 'json.svg'
          },
          {
            name: 'REST API'
          },
          {
            name: 'PostgreSQL',
            uri: 'postgresql.svg'
          },
          {
            name: 'SQL'
          }
        ]
      },
      {
        category: 'Utvecklingsverktyg',
        content: [
          {
            name: 'ESLint',
            uri: 'eslint.svg'
          },
          {
            name: 'Git',
            uri: 'git.svg'
          },
          {
            name: 'GitHub',
            uri: 'github.svg'
          },
          {
            name: 'Google DevTools'
          },
          {
            name: 'IntelliJ IDEA',
            uri: 'intellij-idea.svg'
          },
          {
            name: 'npm',
            uri: 'npm.svg'
          },
          {
            name: 'Visual Studio Code',
            uri: 'vs-code.svg'
          },
          {
            name: 'WordPress',
            uri: 'wordpress.svg'
          }
        ]
      },
      {
        category: 'Design, UX och prototyping',
        content: [
          {
            name: 'Figma',
            uri: 'figma.svg'
          },
          {
            name: 'Miro',
            uri: 'miro.svg'
          },
          {
            name: 'Personas'
          },
          {
            name: 'Procreate',
            uri: 'procreate.svg'
          },
          {
            name: 'UML',
            uri: 'uml.svg'
          },
          {
            name: 'UX- och UI-design'
          }
        ]
      },
      {
        category: 'Andra teknologier',
        content: [
          {
            name: 'bash',
            uri: 'bash.png'
          },
          {
            name: 'FTP'
          },
          {
            name: 'Markdown',
            uri: 'markdown.svg'
          },
          {
            name: 'Slack',
            uri: 'slack.svg'
          },
          {
            name: 'Trello',
            uri: 'trello.svg'
          }
        ]
      },
      {
        category: 'Allmänna färdigheter',
        content: [
          {
            name: 'Agil metodologi'
          },
          {
            name: 'Block Element Modifier',
            uri: 'bem.svg'
          },
          {
            name: 'Business Model Canvas'
          }
        ]
      },
      {
        category: 'Språk',
        content: [
          {
            name: 'Svenska',
            uri: 'swedish.png',
            description: 'Flytande'
          },
          {
            name: 'Engelska',
            uri: 'english.png',
            description: 'Flytande'
          },
          {
            name: 'Kantonesiska',
            uri: 'cantonese.png',
            description: 'Modersmål'
          },
          {
            name: 'Mandarin',
            uri: 'mandarin.png',
            description: 'Flytande'
          }
        ]
      },
      {
        category: 'Hobbier',
        content: [
          {
            name: 'Digitala spel'
          },
          {
            name: 'Fitness'
          },
          {
            name: 'Konst och hantverk'
          },
          {
            name: 'Mat och dryck'
          },
          {
            name: 'Resor'
          },
          {
            name: 'Volontärarbete'
          },
          {
            name: 'Webbutveckling'
          }
        ]
      }
    ]
  },
  projects: {
    name: 'Projekt',
    description: 'Beskrivning',
    skills: 'Färdigheter',
    showDemo: 'Visa demo',
    gitHub: 'GitHub',
    comingSoon: 'Kommer snart',
    folders: [
      {
        name: 'Portfolio',
        uri: 'portfolio.png',
        description: 'En personlig portföljwebbplats som visar mitt arbete, färdigheter och kontaktinformation.',
        skills: 'React, prop-types, Sass, i18n, React Router',
        alt: 'Portfolio'
      },
      {
        name: 'Action Ally',
        gitHub: 'https://github.com/yintinggrace/action-ally',
        uri: 'action-ally.png',
        description: 'En att-göra-lista-app för enkel uppgiftshantering. Användare kan välja logotyper för kategorier vilket förbättrar organisation och personalisering.',
        skills: 'React, TypeScript, styled-components, React Router',
        demo: 'https://www.gracechan.se/projects/action-ally',
        alt: 'Action Ally'
      },
      {
        name: 'Weathery',
        uri: 'weathery.png',
        description: 'En väderapplikation som ger daglig temperatur och prognoser för vilken stad som helst.',
        skills: 'React, TypeScript, Tailwind CSS, React Router',
        alt: 'Väderapp',
        comingSoon: true
      },
      {
        name: 'Women In Tech Alliance',
        uri: 'WITA.png',
        description: 'En community-webbplats dedikerad till att stärka kvinnor inom tech genom att främja nätverk, innovation och tillväxt över branscher.',
        skills: 'CMS-hantering, CSS, JavaScript, UX',
        demo: 'https://womenintechalliance.com/',
        alt: 'WITA'
      },
      {
        name: 'Queer Youth Uganda',
        uri: 'QYU.png',
        description: 'En utbildningsplattform som stärker Ugandas HBTQ-community med resurser, berättelser och säkra onlinekontakter.',
        skills: 'CMS-hantering, CSS, JavaScript, UX, SEO, Projekt- och kundhantering, Agil projektledning',
        demo: 'https://queeryouthuganda.com/',
        alt: 'QYU'
      }
    ]
  }
};

export default sv;
