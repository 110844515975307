const en = {
  header: {
    languages: {
      swedish: 'Swedish',
      english: 'English'
    },
    logo: 'Logo'
  },
  common: {
    title: 'Grace Chan | Front-End Developer',
    name: 'Grace Chan'
  },
  home: {
    name: 'Home',
    title: 'Front-end Developer',
    inMalmo: 'in Malmö',
    arrow: 'Arrow',
    contacts: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/yintinggrace/',
        icon: 'fa-brands fa-linkedin',
        openNewTab: true
      },
      {
        name: 'GitHub',
        link: 'https://github.com/yintinggrace/',
        icon: 'fa-brands fa-github',
        openNewTab: true
      },
      {
        name: 'Email',
        link: 'mailto:yintingchancytg@gmail.com',
        icon: 'fa-solid fa-envelope',
        openNewTab: false
      }
    ],
    iSpecialiseIn: 'I specialise in',
    specialization: [
      {
        name: 'React',
        uri: 'react-negative.svg'
      },
      {
        name: 'Next.js',
        uri: 'next-js-negative.svg'
      },
      {
        name: 'TypeScript',
        uri: 'typescript-negative.svg'
      }
    ]
  },
  aboutMe: {
    name: 'About Me',
    facts: [
      {
        fact: 'Highly motivated React enthusiast crafting user interfaces for an elevated experience.',
        uri: 'react.png',
        alt: 'React'
      },
      {
        fact: 'I love teamwork — it is the key to reaching greater heights and making the journey more enjoyable!',
        uri: 'team.png',
        alt: 'Team'
      },
      {
        fact: 'More than coding—I integrate UX design for well-functioning, stylish websites.',
        uri: 'ui-ux.png',
        alt: 'UI/UX'
      },
      {
        fact: 'I shifted from social work to tech—making an impact is exhilarating!',
        uri: 'computer.png',
        alt: 'Computer'
      },
      {
        fact: 'Daytime code creator, nighttime artsy explorer, on holiday an adventurous traveller.',
        uri: 'fun.png',
        alt: 'Hobbies'
      },
      {
        fact: 'Coffee is cool, but I am sticking to tea all day.',
        uri: 'tea.png',
        alt: 'Tea'
      }
    ]
  },
  skills: {
    name: 'Skills',
    filterBtn: 'Filter',
    selectAll: 'Select All',
    unselectAll: 'Unselect All',
    categories: [
      {
        category: 'Web Development Languages',
        content: [
          {
            name: 'C#',
            uri: 'c-sharp.svg'
          },
          {
            name: 'CSS',
            uri: 'css.svg'
          },
          {
            name: 'HTML',
            uri: 'html.svg'
          },
          {
            name: 'Java',
            uri: 'java.svg'
          },
          {
            name: 'JavaScript',
            uri: 'javascript.svg'
          },
          {
            name: 'PHP',
            uri: 'php.svg'
          },
          {
            name: 'TypeScript',
            uri: 'typescript.svg'
          }
        ]
      },
      {
        category: 'JavaScript Frameworks & Libraries',
        content: [
          {
            name: 'D3',
            uri: 'd3.svg'
          },
          {
            name: 'Express.js',
            uri: 'express-js.svg'
          },
          {
            name: 'Font Awesome',
            uri: 'font-awesome.svg'
          },
          {
            name: 'i18next',
            uri: 'i18next.svg'
          },
          {
            name: 'Jest',
            uri: 'jest.svg'
          },
          {
            name: 'Next.js',
            uri: 'next-js.svg'
          },
          {
            name: 'Node.js',
            uri: 'node-js.svg'
          },
          {
            name: 'PropTypes'
          },
          {
            name: 'React',
            uri: 'react.svg'
          },
          {
            name: 'React Router',
            uri: 'react-router.svg'
          },
          {
            name: 'Redux',
            uri: 'redux.svg'
          }
        ]
      },
      {
        category: 'Styling',
        content: [
          {
            name: 'Responsive Web Design'
          },
          {
            name: 'Sass',
            uri: 'sass.svg'
          },
          {
            name: 'styled-components',
            uri: 'styled-components.svg'
          }
        ]
      },
      {
        category: 'Data',
        content: [
          {
            name: 'JSON',
            uri: 'json.svg'
          },
          {
            name: 'REST API'
          },
          {
            name: 'PostgreSQL',
            uri: 'postgresql.svg'
          },
          {
            name: 'SQL'
          }
        ]
      },
      {
        category: 'Development Tools',
        content: [
          {
            name: 'ESLint',
            uri: 'eslint.svg'
          },
          {
            name: 'Git',
            uri: 'git.svg'
          },
          {
            name: 'GitHub',
            uri: 'github.svg'
          },
          {
            name: 'Google DevTools'
          },
          {
            name: 'IntelliJ IDEA',
            uri: 'intellij-idea.svg'
          },
          {
            name: 'npm',
            uri: 'npm.svg'
          },
          {
            name: 'Visual Studio Code',
            uri: 'vs-code.svg'
          },
          {
            name: 'WordPress',
            uri: 'wordpress.svg'
          }
        ]
      },
      {
        category: 'Design, UX & Prototyping',
        content: [
          {
            name: 'Figma',
            uri: 'figma.svg'
          },
          {
            name: 'Miro',
            uri: 'miro.svg'
          },
          {
            name: 'Personas'
          },
          {
            name: 'Procreate',
            uri: 'procreate.svg'
          },
          {
            name: 'UML',
            uri: 'uml.svg'
          },
          {
            name: 'UX & UI Design'
          }
        ]
      },
      {
        category: 'Other Technologies',
        content: [
          {
            name: 'bash',
            uri: 'bash.png'
          },
          {
            name: 'FTP'
          },
          {
            name: 'Markdown',
            uri: 'markdown.svg'
          },
          {
            name: 'Slack',
            uri: 'slack.svg'
          },
          {
            name: 'Trello',
            uri: 'trello.svg'
          }
        ]
      },
      {
        category: 'General Skills',
        content: [
          {
            name: 'Agile Methodology'
          },
          {
            name: 'Block Element Modifier',
            uri: 'bem.svg'
          },
          {
            name: 'Business Model Canvas'
          }
        ]
      },
      {
        category: 'Languages',
        content: [
          {
            name: 'Swedish',
            uri: 'swedish.png',
            description: 'Fluent'
          },
          {
            name: 'English',
            uri: 'english.png',
            description: 'Fluent'
          },
          {
            name: 'Cantonese',
            uri: 'cantonese.png',
            description: 'Native'
          },
          {
            name: 'Mandarin',
            uri: 'mandarin.png',
            description: 'Fluent'
          }
        ]
      },
      {
        category: 'Hobbies',
        content: [
          {
            name: 'Art and crafting'
          },
          {
            name: 'Fitness'
          },
          {
            name: 'Food and drink'
          },
          {
            name: 'Travelling'
          },
          {
            name: 'Video games'
          },
          {
            name: 'Volunteering'
          },
          {
            name: 'Web development'
          }
        ]
      }
    ]
  },
  projects: {
    name: 'Projects',
    description: 'Description',
    skills: 'Skills',
    showDemo: 'Show Demo',
    gitHub: 'GitHub',
    comingSoon: 'Coming Soon',
    folders: [
      {
        name: 'Portfolio',
        uri: 'portfolio.png',
        description: 'A personal portfolio website displaying my work, skills, and contact information.',
        skills: 'React, prop-types, Sass, i18n, React Router',
        alt: 'Portfolio'
      },
      {
        name: 'Action Ally',
        gitHub: 'https://github.com/yintinggrace/action-ally',
        uri: 'action-ally.png',
        description: 'A to-do list app for easy task management. Users can select logos for categories, enhancing organisation and personalisation.',
        skills: 'React, TypeScript, styled-components, React Router',
        demo: 'https://www.gracechan.se/projects/action-ally',
        alt: 'Action Ally'
      },
      {
        name: 'Weathery',
        uri: 'weathery.png',
        description: 'A weather application that provides daily temperature and forecasts for any city.',
        skills: 'React, TypeScript, Tailwind CSS, React Router',
        alt: 'Weather App',
        comingSoon: true
      },
      {
        name: 'Women In Tech Alliance',
        uri: 'WITA.png',
        description: 'A community website dedicated to empowering women in tech by fostering connections, innovation, and growth across industries.',
        skills: 'CMS Management, CSS, JavaScript, UX',
        demo: 'https://womenintechalliance.com/',
        alt: 'WITA'
      },
      {
        name: 'Queer Youth Uganda',
        uri: 'QYU.png',
        description: 'An educational platform empowering Uganda’s LGBTQ community with resources, storytelling, and safe online connections.',
        skills: 'CMS Management, CSS, JavaScript, UX, SEO, Project & Client Management, Agile Project Management',
        demo: 'https://queeryouthuganda.com/',
        alt: 'QYU'
      }
    ]
  }
};

export default en;
